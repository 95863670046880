/** @jsx jsx */
import { jsx, Grid, Flex, Button, Styled, Container } from "theme-ui"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Song from "../components/song"
import Img from "gatsby-image"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Fantasiakappaleita lapsille" />
    <Grid
      sx={{
        gridTemplateColumns: [null, null, "1fr 1fr"],
        gridGap: [null, null, 5],
        alignItems: "center",
        height: ["auto", null, "calc(100vh - 98px)"],
        maxHeight: ["auto", null, 640, 800],
        mt: [60, 98],
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          order: 1,
          zIndex: 1,
          maxWidth: ["auto", null, 760, 640],
          px: 4,
          mb: 4,
          mr: "auto",
          mx: ["auto", null, null, 0],
        }}
      >
        <Styled.h1
          sx={{
            mt: [0, 4],
            mb: 3,
            fontSize: ["9vw", 5, 6, 7],
          }}
        >
          Fantasiakappaleita lapsille
        </Styled.h1>
        <Styled.p
          sx={{
            fontSize: [2, 3],
          }}
        >
          Fantasiakappaleita lapsille sisältää tunnelmallisia perustasojen 1-2
          pianokappaleita. Vihkon alusta löytyvät helpoimmat kappaleet, ja
          loppupuolelta vaikeimmat. Samaa vihkoa voi siten käyttää
          soitto-opintojen eri vaiheissa. Mukana on kaksi nelikätistä
          kappaletta, joista jälkimmäisestä olen kollegan tilauksesta tehnyt
          myös kahdeksankätisen version. Sävellysten aiheet ovat lapsille
          tuttuja: eläimiä, luontoaiheita, fantasiahahmoja ja mielikuvitusta
          kutkuttavia tunnelmia.
        </Styled.p>
        <Grid
          sx={{
            mt: 3,
            gridGap: 2,
            gridTemplateColumns: [null, "repeat(2, minmax(auto, max-content))"],
          }}
        >
          <Button
            as="a"
            href="https://sulasol.fi/nuottikauppa/fantasiakappaleita-lapsille/"
          >
            Osta verkkokaupasta
          </Button>
          <Button variant="default" as="a" href="https://youtu.be/y8uoNsw_WuQ">
            Katso esittelyvideo
          </Button>
        </Grid>
      </Flex>
      <Flex
        sx={{
          px: [3, null, 0],
          justifyContent: ["center", null, "flex-end"],
          height: ["auto", null, "100%"],
        }}
      >
        <Img
          sizes={{
            ...data.fantasiakappaleita.childImageSharp.fluid,
          }}
          imgStyle={{ objectPosition: "center right" }}
          sx={{
            width: ["100%", "80%", "100%"],
            maxWidth: [640, null, null, 800],
            order: 0,
            zIndex: 0,
          }}
        />
      </Flex>
    </Grid>

    <Container variant="narrow" sx={{ px: [4, 0], mt: [3, null, 4] }}>
      <Song
        title="Tiikerin tanssi"
        description="Tiikerin tanssi on rytmikäs ja helppo alkeiskappale, joka soveltuu jo ensimmäistä vuotta pianoa soittavalle. Vasemman käden reipas terssisäestys on helppo oppia ja melodiassa on ”tiikeri”-rytmiä. Keskiosassa tiikeri hiiviskelee ja vaanii, ja lopuksi palataan tanssitunnelmaan."
        image={data.tiikerinTanssi}
      />
      <Song
        title="Kaislat"
        description="Kaislat on pedaalikappale, jonka voi soittaa pitämällä pedaalia pohjassa kappaleen alusta loppuun. Helminauhamaiset sävelryöpyt soitetaan keveillä sormenliikkeillä, ja kappaleen opetteleminen sujuu helposti mustien koskettimien ryhmittelyä hyödyntäen. Kappale on herkkä sointitutkielma Des-duurissa, jossa harjoitellaan myös käsien tehtävien eriyttämistä."
        image={data.kaislat}
      />
      <Song
        title="Ulpukka"
        description="Ulpukka on helppo nelikätinen pedaalikappale, jossa secondo-osuus on hieman vaativampi ja primo-osuus puolestaan erittäin helppo. Primo-osuus on viiden sormen asemassa ja kahden käden unisonossa, kun taas secondo soittaa liplattavia murrettuja sointuja. Alkusoitossa onkin selvä viittaus kuuluisaan Camille Saint-Saensin Joutseneen. Ehkä joutsen uiskentelee ulpukkalammella?"
        image={data.ulpukka}
      />
      <Song
        title="Kissan uni"
        description="Kissan uni on viittaus vanhaan kunnon Kissanpolkkaan, jossa käytetään avuksi pianon koskettimien visuaalisuutta sävelten muistamisessa. Kissan uni alkaa samoista koskettimista kuin Kissanpolkkakin, mutta kappaleen tunnelma on aivan erilainen. Tämä mustien koskettimien kappale on käytännössä helppo alkeiskappale, joka opetellaan matkimalla, korvakuulolta ja ”maastomuistin” perusteella. Käsien ristiinvieminen on oppilaista aina hauskaa, ja pedaalinkäyttöäkin tulee tässä kappaleessa harjoiteltua."
        image={data.kissanUni}
      />
      <Song
        title="Muurahaisten marssi"
        description="Muurahaisten marssi on staccato-harjoitus ja vasemman käden peukaloharjoitus. Totisesti puurtavat muurahaiset etenevät tasaisessa rytmissä tässä pienessä marssisävellyksessä. Pienessä väliosassa on muutama yllättävä sointu - riitelevätkö muurahaiset? Lopuksi muurahaisten työ jatkuu taas hyvässä järjestyksessä."
        image={data.muurahaistenMarssi}
      />
      <Song
        title="Matsutake-tanssi"
        description="Matsutake-tanssi kertoo sienestyshulluudesta, joka voi iskeä loppukesästä. Matsutake eli männyntuoksuvalmuska on erityisesti Japanissa arvostettu sieni, ja niitä löytyy hyvinä sienivuosina myös Suomen Lapista. Hurjarytmisessä tanssissa on säpinää: nopeaa kuviointia ja vasemman käden näppäryyttä vaativia käden ristiinviemisiä sekä ärhäköitä voimavaihteluita. Oikean käden asento asettuu mukavasti siten, että peukalo on valkoisella f-koskettimella ja pidemmät sormet mustien koskettimien ”yläkerrassa”."
        image={data.matsutakeTanssi}
      />
      <Song
        title="Lentävä kala"
        description="Lentävä kala on etydimäinen ja iloinen kappale, joka kuuluu soittaa lennokkaasti ja luistavasti. Kappaleessa on yllättäviä sointuyhdistelmiä ja vauhdikkaita käänteitä. Tämä kappale vaatii pedaalin sopivaa annostelua ja keveitä sormia."
        image={data.lentavaKala}
      />
      <Song
        title="Keijut"
        description="Keijut on polyfoniaharjoitus, jossa vasen käsi aloittaa melodian muotoilun ja oikea käsi säestää liikkuvaa kuvioita toistavalla vastamelodialla. Hetkeksi kaksi keijua kohtaavat - kädet soittavat unisonossa - kunnes toisella on soolovuoro. Herkkä ja tunnelmallinen kappale kuljettaa keijumetsän tunnelmaan, ja lopuksi keijut vetäytyvät varjoihin."
        image={data.keijut}
      />
      <Song
        title="Surupilviä"
        description="Surupilviä on lyhyt tunnelmakappale, jossa harjoitellaan pedaalinkäyttöä, käsien ristiin viemistä sekä herkkien sointivärien kuuntelua. Terssit ja kvintit luovat ”pilviä”, joista kappaleen keskiosassa virtaa sadetta - tai kyyneliä. Tunnelma on pidättynyt ja sulkeutunut, jopa haikea."
        image={data.surupilvia}
      />
      <Song
        title="Kaleidoskooppi"
        description="Kaleidoskooppi -kappaleesta ehkä arvaa, että yksi omista lempisäveltäjistäni on ranskalainen impressionisti Claude Debussy. Kappaleen kelluvat terssit soitetaan pumpulinpehmeästi, soinnin herkkää sävyä kuunnellen. Kappale on lyhyt ja yksinkertainen, jotta nuottien omaksumiseen ei kuluisi paljon aikaa, ja että soittaja pääsee heti harjoittelemaan sointivärin etsimistä."
        image={data.kaleidoskooppi}
      />
      <Song
        title="Vaahtokarkkivakooja"
        description="Vaahtokarkkivakooja on vauhdikas kappale, jossa seikkailee veikeä mielikuvitushahmo. Leikkisä kappale kutsuu irrottelemaan ja sen sävyt ovat yllättävät ja monipuoliset. Kappale vaatii hieman näppäryyttä ja ennen kaikkea pilkettä silmäkulmaan."
        image={data.vakooja}
      />
      <Song
        title="Ametistiluola"
        description="Ametistiluola on salaperäinen valssisävellys, jossa ametistit kimmeltävät hämärässä luolassa violetinhohtoisina. Tässä mollisävyisessä pedaalikappalessa harjoitellaan kolmisointuja, joita on yhdistelty jännittävällä tavalla. Oikean käden melodia soitetaan kirkkaasti ja vasen käsi säestää keinuvasti ja pehmeästi."
        image={data.ametisti}
      />
      <Song
        title="Neitoperhonen"
        description="Neitoperhonen -kappaleessa hyödynnetään pianon koskettimien symmetriaa d-sävelen ympärillä. Kappaleen alku- ja loppusoitossa perhonen levittää siivet - ja soittaja sormensa - d:n ympärille mustille koskettimille. Nopeat, pehmeästi soitettavat murretut soinnut avautuvat perhosen siipien lailla. Kappaleen nuottikuva on haastavan näköinen, mutta opettajan havainnollistaessa käsien liikettä oppilaalle sävellys onkin helppo oppia soittamaan. Alkusoiton jälkeen vasen käsi soittaa melodiaa mustilla koskettimilla ja oikea käsi säestää - pieni aivojumppa soittajalle, kun käsien tehtävät ovatkin näin päin."
        image={data.neitoperhonen}
      />
      <Song
        title="Varjoisa maa"
        description="Varjoisa maa -kappaleessa vasemman ja oikean käden melodiat vuorottelevat. Hiipivän ja hämyisen kappaleen haasteena on soinnin tarkka hallinta ja keskiosan liikkuvat pariäänet oikealla kädellä. Rauhallinen tempo antaa aikaa musiikin muotoilulle. Nuottikuva on selkeä ja sävellaji helppo d-molli. Mausteena on silloin tällöin alennettu kvinttisävel as, joka luo kipeän kohdan musiikkiin."
      />
      <Song
        title="Merenneito"
        description="Merenneito kertoo vedenalaisen maailman pulppuilevasta ja tunnelmasta, jossa aallot keinuvat ja meren pinta väreilee ja välkehtii. Merenneidon salaperäinen laulu kuuluu kirkkaana pehmeästi soitettavien nopeiden murtosointujen välissä. Oletko kuunnellut myös Maurice Ravelin Merenneidon (Ondine)? Se on yksi lempikappaleistani!"
        image={data.merenneito}
      />
      <Song
        title="Linnunrata"
        description="Linnunrata on nopeasti kiitävä ja dramaattinen mustien koskettimien etydi, joka on monen oppilaan suosikkikappale. Kappaleen vauhdikkaat mutkat on melko helppo hahmottaa, vaikka kappaleen sointikuva on virtuoosinen. Kappaleen galaktiset, kylmänviileät sävyt toteutuvat, kun sormenpäissä on sopivasti pitoa ja vasemman käden bassot antavat muhkeaa tukea. Kappaleen lopussa tumma yötaivas haalenee kohti aamua, ja tähdet katoavat näkyvistä."
        image={data.linnunrata}
      />
      <Song
        title="Lumottu ruusu"
        description="Lumottu ruusu, josta löytyy vihkosta sekä nelikätinen versio että kahdeksankätinen kahden pianon versio, on samaan aikaan sekä melodinen että keinuvan rytmikäs ja meditatiivinen. Kahdeksankätisen version stemmojen vaikeustaso vaihtelee, niin että myös opinnoissaan hieman eri vaiheessa olevat oppilaat voivat soittaa kappaletta yhdessä. Melodia soi kauniisti tersseissä ja seksteissä. Varsinkin kahdeksankätinen versio on parhaimmillaan muhkea sointien aallokko ja antaa soittajilleen yhteismusisoinnin iloa."
        image={data.lumottuRuusu}
      />
    </Container>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    fantasiakappaleita: file(
      relativePath: { eq: "fantasiakappaleita-lapsille.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tiikerinTanssi: file(relativePath: { eq: "tiikerin-tanssi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kaislat: file(relativePath: { eq: "kaislat.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ulpukka: file(relativePath: { eq: "ulpukka.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kissanUni: file(relativePath: { eq: "kissan-uni.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    muurahaistenMarssi: file(relativePath: { eq: "muurahaisten-marssi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    matsutakeTanssi: file(relativePath: { eq: "matsutake-tanssi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lentavaKala: file(relativePath: { eq: "lentava-kala.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    keijut: file(relativePath: { eq: "keijut.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    surupilvia: file(relativePath: { eq: "surupilvia.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    neitoperhonen: file(relativePath: { eq: "neitoperhonen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kaleidoskooppi: file(relativePath: { eq: "kaleidoskooppi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vakooja: file(relativePath: { eq: "vakooja.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ametisti: file(relativePath: { eq: "ametisti.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    merenneito: file(relativePath: { eq: "merenneito.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    linnunrata: file(relativePath: { eq: "linnunrata.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lumottuRuusu: file(relativePath: { eq: "lumottu-ruusunen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
